<template>
<v-app>
    <v-main>
      <v-container fluid fill-height class="d-flex justify-center align-center">
          <Sign :c="this.content" :id="this.idClient" :a="this.auth" :d="this.date" w="360px"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {BASE_URL} from './constants.js'
import Sign from "../components/Sign.vue";
export default {
  name: 'SignDocument',
  data: () => ({
    value: true,
    message: '',
    disabled: 'false',
    auth: '',
    content: '',
    date: '',
    idClient: 0,
  }),
  async mounted() {
    this.content = this.$route.query.d;
    this.date = this.$route.query.f;
    this.idClient = parseInt(this.$route.query.p);
    this.auth = this.$route.query.a;
  },
  methods: {
    
  },
  components: {
    Sign,
  },
}
</script>