import { render, staticRenderFns } from "./HistoryDocument.vue?vue&type=template&id=78575eaa&scoped=true&"
import script from "./HistoryDocument.vue?vue&type=script&lang=js&"
export * from "./HistoryDocument.vue?vue&type=script&lang=js&"
import style0 from "./HistoryDocument.vue?vue&type=style&index=0&id=78575eaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78575eaa",
  null
  
)

export default component.exports