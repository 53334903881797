import genericReload from '../components/genericReload';
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as basics from "../components/basics.js";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: '',
    reload: 0,
    idUser: 0,
    user: '',
    typeUser: '',
    clients: [],
    professionals: [],
    allProfessionals: [],
    companies: [],
    messages: [],
    specialties: [{id: 1, name: "Nutrición"},{id: 2, name: "Psicología"},{id: 3, name: "Enfermería"},{id: 4, name: "Psiquiatría"},{id: 5, name: "Pedagogía"}],
    diagnostic: [],
    reasons: [],
    typeClient: [],
    typeCompany: [],
    professionalsTypes: [{value: "admin", name: "Administrador"},{value: "coord", name: "Coordinador"},{value: "nutricionista", name: "Nutricionista"},{value: "psicologo", name: "Psicólogo"},{value: "enfermero", name: "Enfermero"},{value: "psiquiatra", name: "Psiquiatra"},{value: "pedagogo", name: "Pedagogo"}],
    filterCalendarPro: 0,
    filterCalendarSpe: 0,
    clientSelected: null, // Client selected on Clientes's view to assign a new appointment in calendar
    keepAlive: true,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      paths: ['auth', 'idUser', 'user', 'typeUser', 'filterCalendarSpe'],
    }),
  ],
  mutations: {
    modifyAuth(state, newAuth){
      state.auth = newAuth
    },
    modifyClientSelected(state, newValue){
      state.clientSelected = newValue
    },
    modifyUser(state, newId){
      state.idUser = newId;
    },
    modifyNameUser(state, newName){
      state.user = newName;
    },
    modifyTypeUser(state, newType){
      state.typeUser = newType;
    },
    modifyKeepAlive(state, val){
      state.keepAlive = val;
    },
    modifyReload(state, val){
      state.reload = val;
    },
    addPatient(state, newClient){
      state.clients.push(newClient);
      state.clients = state.clients.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addDiagnostico(state, newItem){
      state.diagnostic.push(newItem);
      state.diagnostic = state.diagnostic.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addReason(state, newItem){
      state.reasons.push(newItem);
      state.reasons = state.reasons.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addTypeClient(state, newItem){
      state.typeClient.push(newItem);
      state.typeClient = state.typeClient.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    addTypeCompany(state, newItem){
      state.typeCompany.push(newItem);
      state.typeCompany = state.typeCompany.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    }
  },
  actions: {
    async loadData(){
      if(this.state.auth != ''){
        await this.dispatch("getClients");
        await this.dispatch("getProfessionals");
        await this.dispatch("getCompanies");
        await this.dispatch("getDiagnostic");
        await this.dispatch("getReasons");
        await this.dispatch("getTypeClient");
        await this.dispatch("getTypeCompany");
      }
    },
    async getClients(){
      const response = await genericReload(this.state.auth, 'api/client');
      if (response.status === 200) {
        this.state.clients = await response.json();
        for (let i = 0; i < this.state.clients.length; i++) {
          this.state.clients[i].nombreRA = basics.removeAccents(this.state.clients[i].nombre);
          this.state.clients[i].apellidosRA = basics.removeAccents(this.state.clients[i].apellidos);
        }
      }
    },
    async getProfessionals(){
      const response = await genericReload(this.state.auth, 'api/user?desactivado=1');
      if (response.status === 200) {
        let json = await response.json();
        this.state.allProfessionals = json;
        this.state.professionals = json.filter(p => p.desactivado == 0);
      }
    },
    async getCompanies(){
      const response = await genericReload(this.state.auth, 'api/company');
      if (response.status === 200) {
        this.state.companies = await response.json();
      }
    },
    async getDiagnostic(){
      const response = await genericReload(this.state.auth, 'api/diagnostic');
      if (response.status === 200) {
        this.state.diagnostic = await response.json();
      }
    },
    async getReasons(){
      const response = await genericReload(this.state.auth, 'api/reason');
      if (response.status === 200) {
        this.state.reasons = await response.json();
      }
    },
    async getTypeClient(){
      const response = await genericReload(this.state.auth, 'api/typeClient');
      if (response.status === 200) {
        this.state.typeClient = await response.json();
      }
    },
    async getTypeCompany(){
      const response = await genericReload(this.state.auth, 'api/typeCompany');
      if (response.status === 200) {
        this.state.typeCompany = await response.json();
      }
    },
  },
  getters: {
    clientById: (state) => (id) => {
      return state.clients.find(client => client.id === id);
    },
    clientByName: (state) => (name, surname) => {
      return state.clients.find(client => client.nombre.toUpperCase() === name && client.apellidos.toUpperCase() === surname);
    },
    professionalById: (state) => (id) => {
      return state.allProfessionals.find(professional => professional.id === id);
    },
    companyById: (state) => (id) => {
      return state.companies.find(company => company.id === id);
    },
    specialtiesById: (state) => (id) => {
      return state.specialties.find(specialtie => specialtie.id === id);
    },
    // messagesById: (state) => (id) => {
    //   return state.messages.find(message => message.id === id);
    // },
  }
})
