import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import RecoverPass from '../components/RecoverPass.vue'
import SignDocument from '../components/SignDocument.vue'
import PrintDocument from '../components/PrintDocument.vue'
import HistoryDocument from '../components/HistoryDocument.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login - Unidad Elca"
    }
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    component: RecoverPass,
    meta: {
      title: "Recuperar contraseña - Unidad Elca"
    },
  },
  {
    path: '/signDocument',
    name: 'SignDocument',
    component: SignDocument,
    meta: {
      title: "Firma de documentos - Unidad Elca"
    },
  },
  {
    path: '/printDocument',
    name: 'PrintDocument',
    component: PrintDocument,
    meta: {
      title: "Documentos firmados - Unidad Elca"
    },
  },
  {
    path: '/historyDocument',
    name: 'HistoryDocument',
    component: HistoryDocument,
    meta: {
      title: "Historial - Unidad Elca"
    },
  },
  {
    path: '/inicio',
    name: 'Inicio',
    meta: {
      title: "Inicio - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Inicio" */ '../views/Inicio.vue')
  },
  {
    path: '/pacientes',
    name: 'Pacientes',
    meta: {
      title: "Pacientes - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Pacientes" */ '../views/Pacientes.vue')
  },
  {
    path: '/citas',
    name: 'Citas',
    meta: {
      title: "Citas - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Citas" */ '../views/Citas.vue')
  },
  {
    path: '/envios',
    name: 'Envios',
    meta: {
      title: "Envíos - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Envios.vue')
  },
  {
    path: '/avisos',
    name: 'Avisos',
    meta: {
      title: "Avisos - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Avisos" */ '../views/Avisos.vue')
  },
  {
    path: '/admin',
    name: 'Panel de administración',
    meta: {
      title: "Panel de administración - Unidad Elca"
    },
    component: () => import(/* webpackChunkName: "Envios" */ '../views/Admin.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if(to.meta.metaTags){
    Array.from(document.querySelectorAll('meta[property]')).map(el => el.parentNode.removeChild(el));
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    to.meta.metaTags.forEach(e => {
      const tag = document.createElement('meta');
      tag.setAttribute(e.attribute, e.name);
      tag.setAttribute("content", e.content);
      tag.setAttribute('data-vue-router-controlled', '');
      document.head.appendChild(tag);
    });
  }
  if(to.name == 'RecoverPass' || to.name == 'SignDocument' || to.name == 'PrintDocument'){
    next();
  }else{
    if (to.name !== 'Login' && (store.state.auth=='' || store.state.auth==undefined)){
      next({ name: 'Login' })
    }else{
      if((to.name == 'Panel de administración' && store.state.typeUser!='admin')||(to.name == 'Envios' && (store.state.typeUser!='admin' && store.state.typeUser!='coord'))){
        next({ name: 'Login' })
      }else{
        next()
      }
    }
  }
})

export default router
