<template>
<v-app>
    <v-main>
      <v-container>
        <v-layout class="d-flex justify-center align-center historyTitle">
          <v-flex xs12 class="text-center">
            <h1>Historial de consultas</h1>
          </v-flex>
        </v-layout>
        <v-layout class="d-flex justify-center align-center">
          <v-img
            alt="Elca Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo2.png"
            transition="scale-transition"
            width="150"
          />
        </v-layout>
        <v-layout class="datosPersonales">
          <v-flex xs5>
            <div v-html="dataClient"></div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs5>
            <div v-html="dataElca"></div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-data-table
              :headers="headersReportHistory"
              :items="items"
              item-key="id"
              hide-default-footer
              :items-per-page="items.length"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.fecha }}</td>
                  <td>{{ item.tipo }}</td>
                  <td class="text-pre-wrap">{{ item.comentario }}</td>
                  <td class="text-pre-wrap">{{ item.tratamiento }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import genericReload from './genericReload';
export default {
  name: 'HistoryDocument',
  data: () => ({
    items: [],
    dataClient: '',
    dataElca: '',
    headersReportHistory: [
      { text: "Fecha", value: "fecha" },
      { text: "Tipo", value: "tipo" },
      { text: "Consulta", value: "comentario" },
      { text: "Tratamiento", value: "tratamiento" },
    ],
  }),
  async mounted() {
    const a = this.$route.query.a;
    const data = JSON.parse(localStorage.getItem('items'));

    const responseClient = await genericReload(a, 'api/client/'+data[0].idCliente);
    const jsonClient = await responseClient.json();
    this.dataClient = jsonClient[0].apellidos+", "+jsonClient[0].nombre+"<br>"+jsonClient[0].email+"<br>"+jsonClient[0].telefono+"<br>"+jsonClient[0].domicilio;
    
    const responseCenter = await genericReload(a, 'api/centers/1');
    const jsonCenter = await responseCenter.json();
    this.dataElca = jsonCenter[0].nombre+"<br>"+jsonCenter[0].email+"<br>"+jsonCenter[0].telefono+"<br>"+jsonCenter[0].direccion+"<br>"+jsonCenter[0].localidad+" "+jsonCenter[0].cp;
    
    this.items = data;

    this.printDocument();
  },
  methods: {
    printDocument() {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    },
  },
}
</script>

<style scoped>
.historyTitle{
  margin-bottom: 20px;
}
.datosPersonales{
  margin-top: 70px;
  margin-bottom: 70px;
}
</style>