<template>
<v-app>
    <v-main>
      <v-container fluid fill-height class="d-flex justify-center align-center">
        <v-layout>
          <div v-html="content"></div>
        </v-layout>
        <v-layout>
          <v-img
            :src=signature
            max-width="300px"
            max-height="300px"
          />
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import genericReload from '../components/genericReload';
export default {
  name: 'PrintDocument',
  data: () => ({
    content: '',
    signature: '',
  }),
  async mounted() {
    const id = this.$route.query.d;
    const a = this.$route.query.a;
    const response = await genericReload(a, 'api/sign/'+id);
    if (response.status === 200) {
      const json = await response.json();
      this.content=json[0].contenido+"<style>p.ql-align-justify > a { display: none !important; } .container.fill-height>.layout{ height: auto !important;}</style>";
      this.signature=json[0].image;

      this.printDocument();
    }
  },
  methods: {
    printDocument() {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    },
  },
}
</script>