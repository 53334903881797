var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(
      _vm.$route.name != 'Login' &&
      _vm.$route.name != 'RecoverPass' &&
      _vm.$route.name != 'SignDocument' &&
      _vm.$route.name != 'PrintDocument' &&
      _vm.$route.name != 'HistoryDocument'
    )?_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('a',{attrs:{"href":"https://www.unidadelca.com/","target":"_blank"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Elca Logo","contain":"","src":require("@/assets/logo.png"),"transition":"scale-transition","width":"150"}})],1)])]):_vm._e(),_c('v-main',[(
        _vm.$route.name != 'Login' &&
        _vm.$route.name != 'RecoverPass' &&
        _vm.$route.name != 'SignDocument' &&
        _vm.$route.name != 'PrintDocument' &&
        _vm.$route.name != 'HistoryDocument'
      )?_c('NavBar'):_vm._e(),_c('v-container',{key:this.$store.state.reload,staticClass:"cMain",attrs:{"fluid":""}},[(this.$store.state.keepAlive)?_c('keep-alive',{attrs:{"include":"Pacientes"}},[_c('router-view')],1):_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }