<template>
  <div>
    <div>
      <h1>{{ c }}</h1>
    </div>
    <div id="formSign">
      <VueSignaturePad
        style="border: 1px solid black; border-radius: 10px; background-color: white;"
        :width="w"
        height="300px"
        ref="signaturePad"
      />
      <div>
        <v-btn
          @click="save"
          tile
          class="botonElca"
        >
          <v-icon left>mdi-content-save</v-icon> Guardar
        </v-btn>
        <v-btn
          @click="undo"
          tile
          class="botonElca"
        >
          <v-icon left>mdi-undo</v-icon> Deshacer
        </v-btn>
      </div>
      <div id="message" style="position: fixed; bottom: 1em;"><h3>{{ message }}</h3></div>
    </div>
  </div>
</template>

<script>
import genericReload from '../components/genericReload';
import {BASE_URL} from '../components/constants.js'
export default {
  name: 'Sign',
  props: {
    w: {
      type: String,
      required: true
    },
    c: {
      type: String,
      required: false
    },
    id: {
      type: Number,
      required: false
    },
    a: {
      type: String,
      required: false
    },
    d: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      message:""
    }
  },
  async mounted() {
    setTimeout(() => {
      this.$refs.signaturePad.resizeCanvas()
    }, 100)
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        document.getElementById("message").style.color="green";
        this.message = "Documento firmado de forma correcta. Ahora puedes cerrar esta página."
        let response=null;
        let id=null;
        if(this.$props.c && this.$props.c!=""){
          response = await genericReload(this.$props.a, 'api/sign?idCliente='+this.$props.id+"&nombre="+this.$props.c);
        }else{
          if(this.$props.d && this.$props.d!=""){
            response = await genericReload(this.$props.a, 'api/sign?idCliente='+this.$props.id+"&fecha="+this.$props.d);
          }
        }
        if (response.status === 200) {
          const json = await response.json();
          id = json[0].id;
        }
        if(id){
          const putData = {
            image: data
          };
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$props.a,
          };

          await fetch(
            `${BASE_URL}/api/sign/${id}`,
            {
              method: "PUT",
              headers: headers,
              body: JSON.stringify(putData),
            }
          );
        }else{
          document.getElementById("message").style.color="red";
          this.message = "Documento no firmado. No se ha podido encontrar ningún documento pendiente de firma con estos datos."
        }
      }else{
        document.getElementById("message").style.color="red";
        this.message = "No has firmado el documento. Haz una firma dentro del cuadro y pulsa en Guardar."
      }
    }
  }
};
</script>
